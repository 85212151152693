import React from "react";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
                href="#epiccasual"
                onClick={e => {
                  e.preventDefault();
                  const aboutUsSection = document.getElementById("index-header");
                  if(aboutUsSection)aboutUsSection.scrollIntoView({block:'start', behavior:"smooth"});
                }}         
              id="navbar-brand"
              style={{fontWeight:'bold'}}
            >
              Epic Casual
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              We make games with             <img
              alt="..."
              className="heart-logo"
              src={require("assets/img/heart-icon.png")}
            ></img>
              {/* <i style={{marginLeft:5, height:5}} className="now-ui-icons ui-2_favourite-28"></i> */}
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#about-us"
                  onClick={e => {
                    e.preventDefault();
                    const aboutUsSection = document.getElementById("about-us-section");
                    if(aboutUsSection)aboutUsSection.scrollIntoView({block:'start', behavior:"smooth"});
                  }}
                >
                  <i style={{fontSize:14, marginRight:5, marginTop:5}} class="fa fa-question-circle"></i>
                  <p style={{fontWeight:'bold'}}>Who We Are</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#about-us"
                  onClick={e => {
                    e.preventDefault();
                    const aboutUsSection = document.getElementById("team-players");
                    if(aboutUsSection)aboutUsSection.scrollIntoView({block:'start', behavior:"smooth"});
                  }}
                >
                  <i style={{fontSize:14, marginRight:5, marginTop:5}} class="fa fa-users"></i>
                  <p style={{fontWeight:'bold'}}>Our Team</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#about-us"
                  onClick={e => {
                    e.preventDefault();
                    const aboutUsSection = document.getElementById("join-our-team");
                    if(aboutUsSection)aboutUsSection.scrollIntoView({block:'start', behavior:"smooth"});
                  }}
                >
                  
                  <i style={{fontSize:14, marginRight:5, marginTop:5}} class="fa fa-user-plus"></i>
                  <p style={{fontWeight:'bold'}}>Join Our Team</p>
                </NavLink>
              </NavItem>              
              
              <NavItem>
                <NavLink
                  href="#games"
                  onClick={e => {
                    e.preventDefault();
                    const aboutUsSection = document.getElementById("games-section");
                    if(aboutUsSection)aboutUsSection.scrollIntoView();
                  }}
                >
                  <i style={{fontSize:14, marginRight:5, marginTop:5}} className="fas fa-gamepad"></i>
                  <p style={{fontWeight:'bold'}}>Games</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://twitter.com/EpicCasual?ref=epiccasual.com"
                  target="_blank"
                  id="twitter-tooltip"
                >
                  <i className="fab fa-twitter"></i>
                  <p className="d-lg-none d-xl-none">Twitter</p>
                </NavLink>
                <UncontrolledTooltip target="#twitter-tooltip">
                  Follow us on Twitter
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/EpicCasual-109849880650658?ref=epiccasual.com"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.youtube.com/channel/UCEGvdNNKmz7Bcu_t_0U6JgQ"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-youtube"></i>
                  <p className="d-lg-none d-xl-none">YouTube</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on YouTube
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;

import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
const TeamPlayers = [
  {
    imgSrc:require('assets/img/andrii-m.jpg'),
    name:"Andrii Mykhailenko",
    role:"CEO, Co-Founder",
    href:"#AndriiM",
    details:<p className="description">
      More details will be soon
    </p>
  },
  {
    imgSrc:require('assets/img/oleg-v.jpg'),
    name:"Oleg Voytenko",
    role:"CVO, Co-Founder",
    href:"#OlegV",
    details:<p className="description">
      More details will be soon
    </p>
  },  
  {
    imgSrc:require('assets/img/ekaterina-m.jpg'),
    name:"Ekaterina Mykhailenko",
    role:"CFO, Co-Founder",
    href:"#EkaterinaM",
    details:<p className="description">
      More details will be soon
    </p>
  },
  {
    imgSrc:require('assets/img/andrii-barvynko.png'),
    name:"Andrii Barvynko",
    role:"CTO, Co-Founder",
    href:"#AndriiM",
    details:<p className="description">
      More details will be soon
    </p>
  },
  {
    imgSrc:require('assets/img/vladimir-k.jpg'),
    name:"Vladimir Kok",
    role:"Sound Producer, Co-Founder",
    href:"#AndriiM",
    details:<p className="description">
      More details will be soon
    </p>
  }, 
  {
    imgSrc:require('assets/img/nazar-k.jpeg'),
    name:"Nazar Kernytskyy",
    role:"Hacker, Automation QA Guru, Co-Founder",
    href:"#NazarK",
    details:<p className="description">
      More details will be soon
    </p>
  },
  {
    imgSrc:require('assets/img/julia-k.jpg'),
    name:"Julia Ivaskiv",
    role:"2D Artist Guru, Co-Founder",
    href:"#Julia",
    details:<p className="description">
      More details will be soon
    </p>
  },
  {
    imgSrc:require('assets/img/danil-zmyrov.jpg'),
    name:"Danyl Zhmyrov",
    role:"Front End Guru, Co-Founder",
    href:"#Danyl",
    details:<p className="description">
      More details will be soon
    </p>
  },
  {
    imgSrc:require('assets/img/artem-sidun.jpg'),
    name:"Artyom Sidun",
    role:"Full Stack Guru, Co-Founder",
    href:"#Artyom",
    details:<p className="description">
      More details will be soon
    </p>
  },   
]
//QA Automation
function TeamPlayersBlock(){

  return <Container>
          <h2 className="title">Here is our team</h2>
          <div className="team">
            <Row>
              {
                TeamPlayers.map(teamPlayer=>{
                  return  <Col md="4">
                            <div className="team-player">
                              <img
                                alt="..."
                                className="rounded-circle img-fluid img-raised"
                                src={teamPlayer.imgSrc}
                              ></img>
                              <h4 className="title">{teamPlayer.name}</h4>
                              <p className="category text-info" style={{maxWidth:280, margin:'auto'}}> {teamPlayer.role} </p>
                              {teamPlayer.details}
                              <Button
                                className="btn-icon btn-round"
                                color="info"
                                href={teamPlayer.href}
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fab fa-twitter"></i>
                              </Button>
                              <Button
                                className="btn-icon btn-round"
                                color="info"
                                href={teamPlayer.href}
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fab fa-instagram"></i>
                              </Button>
                              <Button
                                className="btn-icon btn-round"
                                color="info"
                                href={teamPlayer.href}
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fab fa-facebook-square"></i>
                              </Button>
                            </div>
                          </Col>;
                })
              }
            </Row>
          </div>
        </Container>;
}
function AboutUsSection() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <div id="about-us-section">
        <div className="section section-about-us" id="about-us-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Who we are?</h2>
                <h5 className="description">
                  We are a team of professionals who love to play games, 
                  but most of all - create them. 
                  We anxiously and lovingly make each game, play them ourselves and constantly improve the gameplay.
                  Our games are endowed with great love and a reverent approach to each created element 
                  from our creative development team.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/login.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "We are very proud of all our games. 
                       But to achieve the desired result, we work very hard, 
                       working on every element in the game. 
                       Creating a game can be compared with the birth and upbringing of a child.
                       We, with great love <i class="fas fa-heart"></i> made every game." <br></br>
                      <br></br>
                      <small>- CEO, Andii M</small>
                    </p>
                  </div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg1.jpg") + ")"
                    }}
                  ></div>
                  <h3>
                    So what does the new game actually mean
                  </h3>
                  <p>
                    Each idea of the game is born like a baby. 
                    After a creative idea, our team gathers and organizes 
                    a big brainstorming session,
                    developing an idea is an important stage 
                    in the development of games.
                  </p>
                  <p>
                    Further, our team of professionals brings the idea to life 
                    using the latest technologies, 
                    such as Unity, Blender... <br/>
                    <a
                      href="#technology-stack-section"
                      // style={{color:'black'}}
                      onClick={e => {
                        e.preventDefault();
                        const aboutUsSection = document.getElementById("technology-stack-section");
                        if(aboutUsSection)aboutUsSection.scrollIntoView({block:'start', behavior:"smooth"});
                      }}
                    >
                    and another technology stack described below
                    </a>                    
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-team text-center" id="team-players">
          {TeamPlayersBlock()}
        </div>
        <div className="section section-contact-us text-center" id="join-our-team" >
          <Container>
            <h2 className="title">Want to work with us?</h2>
            <p className="description">Your project is very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
  );
}

export default AboutUsSection;
